<script>
  import BaseButton from "../components/UI/BaseButton.vue";
  import ModalNewClient from "@/components/UI/ModalNewClient.vue";
  export default {
    components: { BaseButton, ModalNewClient },
    data() {
      return {
        newClientModal: false,
      };
    },
    methods: {
      openModal() {},
      closeModal() {
        this.newClientModal = false;
      },
    },
  };
</script>

<template>
  <ModalNewClient v-if="newClientModal" @close-modal="closeModal" />
  <div>
    <h1 class="text-3xl font-semibold text-blue-900">
      Clients
    </h1>
    <div class="mt-12">
      <BaseButton @click="newClientModal = !newClientModal" :mode="success">
        Add New Engagement
      </BaseButton>
    </div>
  </div>
</template>

<style></style>
